/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-05-19 17:26:29
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-03 19:12:35
 */
export const columns = [
{
  title: '序号',
  dataIndex: 'index',
  key: 'index',
  width:50,
  align:'center',
  ellipsis:true,
  scopedSlots: { customRender: 'index'}
},
{
  title: '大区/事务所',
  dataIndex: 'firmName',
  key: 'firmName',
  width:250,
  align:'left',
  ellipsis:true,
  scopedSlots: { customRender: 'firmName'}
},
{
    title: '经销商名称',
    dataIndex: 'dealerName',
    key: 'dealerName',
    width:150,
    align:'center',
    // ellipsis:true
},
{
  title: '店铺名',
  dataIndex: 'storeName',
  key: 'storeName',
  width:150,
  align:'center',
},

  {
    title: '员工名称',
    dataIndex: 'managerName',
    key: 'managerName',
    width:150,
    align:'center',
    ellipsis:true
  },
{
    title: '客户名称',
    dataIndex: 'name',
    key: 'name',
    width:150,
    align:'center',
    ellipsis:true
},
{
  title: '客户来源',
  dataIndex: 'customerSourceName',
  key: 'customerSourceName',
  width:150,
  align:'center',
},
{
    title: '联系方式',
    dataIndex: 'phone',
    key: 'phone',
    width:150,
    align:'center',
    ellipsis:true
},
{
    title: '物业地址',
    dataIndex: 'detailedAddress',
    key: 'detailedAddress',
    width:200,
    align:'center',
},
{
  title: '物业类型',
  dataIndex: 'propertyType',
  key: 'propertyType',
  align:'center',
  width:150,
  scopedSlots: { customRender: 'propertyType'}
},
{
  title: '订单类型',
  dataIndex: 'orderType',
  key: 'orderType',
  align:'center',
  width:150,
  scopedSlots: { customRender: 'orderType'}
},
  {
    title: '成交商品',
    dataIndex: 'chengJiaoProNum',
    key: 'chengJiaoProNum',
    align:'center',
    width:150,
    scopedSlots: { customRender: 'chengJiaoProNum'}
  },
{
    title: '预订单状态',
    dataIndex: 'orderStatus',
    key: 'orderStatus',
    width:150,
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'orderStatus'}
},
  {
    title: '申请状态',
    dataIndex: 'registrationStatus',
    key: 'registrationStatus',
    width:150,
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'registrationStatus'}
  },
{
    title: '审核状态',
    dataIndex: 'verifyStatus',
    key: 'verifyStatus',
    width:150,
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'verifyStatus'}
},
{
  title: '平台来源',
  dataIndex: 'sourceType',
  key: 'sourceType',
  width:100,
  align:'center',
  ellipsis:true,
  scopedSlots: { customRender: 'sourceType'}
},
{
    title: '申请时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width:150,
    align:'center',
    // ellipsis:true
},
{
  title: '成交时间',
  dataIndex: 'dealTime',
  key: 'dealTime',
  width:150,
  align:'center',
  // ellipsis:true
},
// {
//   title: '操作',
//   dataIndex: 'action',
//   key: 'action',
//   width:150,
//   fixed: 'right',
//   scopedSlots: { customRender: 'action'}
//   // ellipsis:true
// },
]
